.addBackgroundImage {
  background-image: url('https://iaesirfinance.com/wp-content/uploads/2023/04/AI-Algorithm3.png') !important;
  background-size: 'cover';
  background-position: 'center';
}

#root.backgroundImage{
  background-image: url('https://iaesirfinance.com/wp-content/uploads/2023/04/AI-Algorithm3.png') !important;
  background-size: 'cover' !important;
  background-position: 'center' !important;
}

.css-v57kt1 {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
  flex-flow: row wrap;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  margin: calc(var(--Grid-rowSpacing) / -2) calc(var(--Grid-columnSpacing) / -2);
}

/*  1*/

.css-167r2rz {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  padding: 80px 24px 40px;
  text-align: center;
}

/*profile first section*/
.css-m6sgpe {
  padding: 8px;
  margin: auto;
  width: 144px;
  height: 144px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50%;
  border: 1px dashed rgba(145, 158, 171, 0.2);
}

/*upload profile*/
.css-1t5p1px {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

/**/
.css-78xr25 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
  border-radius: 50%;
  position: absolute;
  color: rgb(255, 255, 255);
  background-color: rgba(22, 28, 36, 0.64);
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
}

.css-78xr25:hover {
  opacity: 1; /* Set the opacity to 1 when hovering */
}
.css-1u1izvz{
  margin: 24px auto 0px;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  color: rgb(145, 158, 171);
  display: block;
  text-align: center;
}

/* Common styles */
.css-grry9j {
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / var(--Grid-columns));
  min-width: 0px;
  box-sizing: border-box;
  padding: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
}

/* Media query for min-width: 900px */
@media (min-width: 900px) {
  .css-grry9j {
    width: calc(100% * 4 / var(--Grid-columns));
  }
}

/* profile details */

@media (min-width: 900px){
  .css-1gvf2rb {
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-basis: auto;
    width: calc(100% * 8 / var(--Grid-columns));
  }
}

.css-1gvf2rb {
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-basis: auto;
    width: calc(100% * 12 / var(--Grid-columns));
    min-width: 0px;
    box-sizing: border-box;
    padding: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
}

.css-137yw8i {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  padding: 24px;
}


@media (min-width: 600px){
  .css-122zftf {
    grid-template-columns: repeat(2, 1fr);
  }
}

.css-122zftf {
    gap: 24px 16px;
    display: grid;
}

.css-feqhe6 {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  width: 100%;
}

.css-10u88f4 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
  margin-top: 24px;
}


/* .css-137yw8i {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  padding: 24px;
} */

